@font-face {
    font-family: "ProximaNova";
    src: local("ProximaNova"), url('./assets/fonts/ProximaNova-Regular.otf') format("truetype");
}

body, html {
    margin: 0;
    padding: 0;
    font-family: "ProximaNova";
    color: #000000;
    font-size: 16px;
    background: #e3b770;
}

.spot-header {
    min-height: calc(100vh - 142px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spotmonitor-home .spot-header{
    min-height: 100vh;
}

.spot-logo {
    width: auto;
}

.contact-info {
    bottom: 0;
    padding: 20px 0;
    text-align: center;
}

.contact-info a {
    color: #000000;
    text-decoration: none;
    font-size: 14px;
}

.copyright-row {
    font-size: 18px;
    padding-bottom: 10px;
}

.contact-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
}

.link-row a {
    text-decoration: underline;
}

.link-row {
    align-items: center;
    padding: 10px
}

.contact-row .fa-style {
    display: flex;
    align-items: center;
}


.contact-row .fa-style.email {
    padding-bottom: 10px;
}

.contact-row .email-address {
    padding-right: 0;
}

.copyright-row {
    font-size: 14px;
}

.contact-row svg {
    padding-right: 10px;
}

@media only screen and (max-width: 1024px) {
    .spot-logo {
        width: 90%;
    }

}

.body-copy {
    width: 100%;
    max-width: 900px;
    background: white;
    color: black;
    border-radius: 10px;
    margin: 0 auto;
    padding: 10px 20px
}

.body-copy h1 {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 2px solid black;
}
.subpage .spot-logo {
    height: 100px
}

.subpage header {
    min-height: auto;
    padding: 20px
}